import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import Roadmap from './components/Roadmap';
import Darkpaper from './components/Darkpaper';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 901);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 901); // Update state based on screen width
    };

    window.addEventListener('resize', handleResize);  // Attach event listener

    return () => {
      window.removeEventListener('resize', handleResize);  // Cleanup
    };
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Navbar */}
        <nav className="navbar">
          <div className="nav-container">
            <div className="logo">
            <Link to="/"><img className="logo-img" src="Icon-old.png" alt="Logo Icon"></img></Link>  
            </div>

            {/* Hamburger menu button */}
            <div className="menu-icon" onClick={toggleMenu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>

            {/* Navigation Links - Show/Hide based on menuOpen state */}
            <ul className={`nav-links ${menuOpen ? "active" : ""}`} onClick={toggleMenu}>
              <li className="navbutton"><img className="navicon" src="/DarkPaper.png"></img><Link to="/darkpaper" onClick={closeMenu}>darkPaper</Link></li>
              <li className="navbutton"><img className="navicon" src="/roadmap.png"></img><Link to="/roadmap" onClick={closeMenu}>roadMap</Link></li>
              <li className="navbutton"><img className="navicon-repo" src="/DarkRepo.png"></img><Link to="https://github.com/claudebaxter/Dark-Coin-ASA-1088771340" onClick={closeMenu}>darkRepo</Link></li>
              <li className="navbutton"><img className="navicon" src="/Icon-new.png"></img><Link to="https://dark-coin.com" onClick={closeMenu}>dApp</Link></li>
              <li className="navbutton"><img className="navicon" src="/Tinyman.png"></img><Link to="https://app.tinyman.org/#/swap?asset_in=0&asset_out=1088771340" onClick={closeMenu}>Buy DARKCOIN</Link></li>
              {menuOpen && isMobile && (
              <>
                <div className="soc-container2">
                    <a href="https://discord.gg/xdZ6V5ybmq">
                      <img className="socials" src="/discord-icon.png"></img>
                    </a>
                    <a href="https://vestige.fi/asset/1088771340">
                      <img className="socials" src="/vestige-icon.png"></img>
                    </a>
                    <a href="mailto:team@dark-coin.io">
                      <img className="socials" src="/email-icon.png"></img>
                    </a>
                    <a href="https://www.linkedin.com/company/dark-coin-dao/">
                      <img className="socials" src="/linkedin-icon.png"></img>
                    </a>
                </div>
                <div className="soc-container2">
                    <a href="https://www.reddit.com/r/DarkCoinASA/">
                      <img className="socials" src="/reddit-icon.png"></img>
                    </a>
                    <a href="https://twitter.com/DarkCoinASA">
                      <img className="socials" src="/twitter-icon.png"></img>
                    </a>
                    <a href="https://github.com/ToysToTinkerWith/DarkCoin">
                      <img className="socials" src="/github-icon.png"></img>
                    </a>
                    <a href="https://www.youtube.com/@Dark-Coin">
                      <img className="socials" src="/youtube-icon.png"></img>
                    </a>  
                </div>
              </>
            )}
            </ul>
          </div>
        </nav>

        {/* Content Routes */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/darkpaper" element={<Darkpaper />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;